import { useSession } from 'next-auth/client';
import React, { ComponentType } from 'react';
import Redirect from './Redirect';

export default function LoggedOutRoute<P>(
  Component: ComponentType<P> & { layout?: ComponentType<{}> }
): ComponentType<P> {
  function loggedOutRouteWrapper(props: P) {
    let [session, loading] = useSession();

    if (loading) {
      return null;
    }

    return !session ? <Component {...props} /> : <Redirect to="/account" />;
  }

  loggedOutRouteWrapper.layout = Component.layout;

  return loggedOutRouteWrapper;
}
