import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next';
import { getProviders, getCsrfToken } from 'next-auth/client';
import { Await } from 'utils/await';
import Button from 'components/ui/Button';
import Input from './forms/Input';

export interface SignInProps {
  providers: Exclude<Await<ReturnType<typeof getProviders>>, null>;
}

export default function SignIn(props: SignInProps & { redirect?: string }) {
  let router = useRouter();
  let { email, redirect, error } = router.query;
  let [csrfToken, setCsrf] = useState<string | null>(null);

  redirect ??= props.redirect ?? '/account';

  useEffect(() => {
    getCsrfToken().then(token => setCsrf(token));
  }, [setCsrf]);

  let errorMessage;
  if (error) {
    switch (error) {
      case 'Signin':
      case 'OAuthSignin':
      case 'OAuthCallback':
      case 'OAuthCreateAccount':
      case 'EmailCreateAccount':
      case 'Callback':
        errorMessage = <p>Try signing with a different account.</p>;
        break;
      case 'OAuthAccountNotLinked':
        errorMessage = (
          <p>
            To confirm your identity, sign in with the same account you used
            originally.
          </p>
        );
        break;
      case 'EmailSignin':
        errorMessage = <p>Check your email address.</p>;
        break;
      case 'CredentialsSignin':
        errorMessage = (
          <p>Sign in failed. Check the details you provided are correct.</p>
        );
        break;
      default:
        errorMessage = <p>Unable to sign in.</p>;
        break;
    }
  }

  return (
    <div className="stack-6">
      {errorMessage && (
        <div className="text-sm text-yellow-600">{errorMessage}</div>
      )}
      {props.providers.email && (
        <form action={props.providers.email.signinUrl} method="POST">
          <input
            type="hidden"
            name="csrfToken"
            readOnly
            value={csrfToken ?? ''}
          />
          <div className="flex">
            <Input
              id={`input-email-for-${props.providers.email.id}-provider`}
              required
              autoFocus
              type="text"
              name="email"
              defaultValue={email}
              placeholder="your-email@example.com"
              className="w-64"
              size="lg"
            />
            <Button
              size="lg"
              appearance="primary"
              type="submit"
              className="flex-auto ml-2"
            >
              Sign in with Email
            </Button>
          </div>
        </form>
      )}
      <hr />
      {Object.keys(props.providers)
        .filter(p => props.providers[p].type === 'oauth')
        .map((providerName, i) => {
          let provider = props.providers[providerName];
          return (
            <div key={provider.id} className="">
              <form action={provider.signinUrl} method="POST">
                <input type="hidden" name="csrfToken" value={csrfToken ?? ''} />
                {redirect && (
                  <input
                    type="hidden"
                    name="callbackUrl"
                    value={redirect ?? ''}
                  />
                )}
                <Button
                  type="submit"
                  className="button"
                  appearance="primary"
                  size="xl"
                  disabled={!csrfToken}
                >
                  Sign in with {provider.name}
                </Button>
              </form>
            </div>
          );
        })}
    </div>
  );
}

export async function getSignInProps() {
  let providers = await getProviders();
  return {
    props: { providers: providers ?? {} }
  };
}
