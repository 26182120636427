import React, { DetailedHTMLProps, useRef, Ref, forwardRef } from 'react';
import classnames from 'classnames';
import fieldLabelFromName from 'utils/field-label-from-name';
import Icon, { IconName } from '../Icon/Icon';

export interface InputProps
  extends Omit<
    DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'size' | 'ref'
  > {
  className?: string;
  placeholder?: string;
  size?: 'sm' | 'md' | 'lg';
  // leftIcon?: IconName;
  // rightIcon?: IconName;
}

let guid = 0;

export default forwardRef(function Input(
  props: InputProps,
  ref: string | Ref<HTMLInputElement>
) {
  let {
    placeholder,
    size,
    className,
    // leftIcon,
    // rightIcon,
    type,
    value,
    ...otherProps
  } = props;
  let { current: id } = useRef(`input-${guid++}`);

  placeholder = placeholder ?? fieldLabelFromName(props.name);
  size = size ?? 'md';

  // if (type === 'search') {
  //   leftIcon = leftIcon ?? 'search';
  // }

  let paddingY: number;
  let paddingLeft: number;
  let paddingRight: number;
  let iconSize: number;
  let iconPadding: number;
  let fontSize: string;

  switch (size) {
    case 'sm':
      paddingY = 1;
      paddingLeft = 2;
      paddingRight = 2;
      iconSize = 3;
      iconPadding = 1;
      break;
    case 'md':
      paddingY = 2;
      paddingLeft = 2;
      paddingRight = 2;
      iconSize = 4;
      iconPadding = 2;
      break;
    case 'lg':
      paddingY = 3;
      paddingLeft = 4;
      paddingRight = 4;
      iconSize = 5;
      iconPadding = 3;
      break;
  }

  return (
    <div className={classnames('relative rounded-md', className)}>
      <input
        id={id}
        type={type}
        ref={ref}
        className={classnames(
          'form-input block w-full select-auto border shadow-sm',
          `pl-${paddingLeft} pr-${paddingRight} py-${paddingY}`,
          type === 'search' ? 'rounded-lg' : 'rounded'
        )}
        style={{ fontSize: '1em' }}
        placeholder={placeholder}
        value={value === null ? '' : value}
        {...otherProps}
      />
    </div>
  );
});
