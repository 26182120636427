import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Redirect(props: { to: string }) {
  let router = useRouter();

  useEffect(() => {
    router.replace(props.to);
  }, [router, props.to]);

  return null;
}
