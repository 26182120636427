import React from 'react';
import classnames from 'classnames';

import above from './icons/top-arrow-to-top.svg';
import angleDown from './icons/angle-down.svg';
import angleLeft from './icons/angle-left.svg';
import angleRight from './icons/angle-right.svg';
import angleUp from './icons/angle-up.svg';
import arrowDown from './icons/arrow-down.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowRight from './icons/arrow-right.svg';
import arrowUp from './icons/arrow-up.svg';
import below from './icons/arrow-to-bottom.svg';
import bolt from './icons/bolt.svg';
import clock from './icons/clock.svg';
import check from './icons/check.svg';
import checkCircle from './icons/check-circle.svg';
import circleMinus from './icons/minus-circle.svg';
import circleQuestion from './icons/question-circle.svg';
import cog from './icons/cog.svg';
import copy from './icons/copy.svg';
import dismiss from './icons/times.svg';
import edit from './icons/edit.svg';
import etc from './icons/ellipsis-h.svg';
import externalLink from './icons/external-link-alt.svg';
import globe from './icons/globe.svg';
import link from './icons/link.svg';
import location from './icons/location-point.svg';
import lock from './icons/lock.svg';
import plus from './icons/plus.svg';
import projectModeLocal from './icons/laptop.svg';
import projectModeHosted from './icons/globe.svg';
import search from './icons/search-alt.svg';
import star from './icons/star.svg';
import trash from './icons/trash.svg';
import unlock from './icons/unlock.svg';
import user from './icons/user.svg';
import video from './icons/video.svg';

export const Icons = {
  above,
  angleDown,
  angleLeft,
  angleRight,
  angleUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  below,
  bolt,
  clock,
  check,
  checkCircle,
  circleMinus,
  circleQuestion,
  cog,
  copy,
  dismiss,
  edit,
  etc,
  externalLink,
  globe,
  link,
  location,
  lock,
  plus,
  projectModeLocal,
  projectModeHosted,
  record: video,
  search,
  star,
  trash,
  unlock,
  user,
  video
};

export type IconName = keyof typeof Icons;

export interface Props extends React.SVGProps<SVGSVGElement> {
  name: IconName;
  size?: number | string;
}

export default function Icon(props: Props) {
  let { name, size, ...otherProps } = props;
  let Component = Icons[name];
  if (!Component) {
    throw new Error(`No such icon: ${name}`);
  }
  let className = classnames(`icon-${name}`, otherProps.className);
  return (
    <Component
      {...otherProps}
      className={classnames(
        'icon',
        props.size && `w-${size} h-${size}`,
        className
      )}
      style={{
        flex: '0 0 auto',
        ...(props.size ? {} : { width: '1em', height: '1em' }),
        ...otherProps.style
      }}
    />
  );
}
